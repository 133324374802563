.MarketingCards19d764afFef14655A08c032a19f69b63 {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 7rem;
}

.MarketingCards19d764afFef14655A08c032a19f69b63 .MarketingCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
}

@media (min-width: 768px) {
  .MarketingCards19d764afFef14655A08c032a19f69b63 .MarketingCard {
    gap: 6rem;
  }
}

.MarketingCards19d764afFef14655A08c032a19f69b63 .MarketingCard .ImageOverlay {
}

.MarketingCards19d764afFef14655A08c032a19f69b63 .MarketingCard img {
  max-height: 400px;
}

@media (min-width: 768px) {
  .MarketingCards19d764afFef14655A08c032a19f69b63 .CardTextContent {
    width: 25rem;
  }
}

.MarketingCards19d764afFef14655A08c032a19f69b63 .CardHeading {
  color: #500064;
  font-family: Bebas Neue;
  font-size: 3rem;
  letter-spacing: 0.1rem;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
}
@media (min-width: 768px) {
  .MarketingCards19d764afFef14655A08c032a19f69b63 .CardHeading {
    padding-left: 0;
    padding-right: 0;
    line-height: 1.5;
  }
}

.MarketingCards19d764afFef14655A08c032a19f69b63 .CardText {
  font-family: Montserrat;
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
}

.MarketingCards19d764afFef14655A08c032a19f69b63 .ButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

@media (min-width: 768px) {
  .MarketingCards19d764afFef14655A08c032a19f69b63 .ButtonWrapper {
    justify-content: flex-start;
  }
}

.MarketingCards19d764afFef14655A08c032a19f69b63 .Button {
  background-color: #780050;
  color: white;
  font-family: 'Bebas Neue'; 
  font-weight: 700; 
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
}
.HomePage55b17fdf5368485cA4dfC81a5e4ccfed {
  display: flex;
  flex-direction: column;
}

.WelcomeBackPopUp {
  padding: 2rem;
  position: relative;
  background-color: white;
  box-shadow: 5px 5px 5px rgb(50 50 50 / 20%);
}

.WelcomeBackPopUp .CloseButton {
  color: black;
}

.WelcomeBackPopUp .BackgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(https://mp1md-pub.s3.amazonaws.com/spp/images/page-background-photo.jpg);
  opacity: 0.5;
  background-position: center;
  background-size: cover;
}

.WelcomeBackPopUp .Body {
  position: relative;
}

.WelcomeBackPopUp .Button {
  background-color: #780050;
  color: white;
  font-family: 'Bebas Neue';
  font-size: 120%;
  letter-spacing: 0.05rem;
  padding: 0.5rem 2rem;
  text-align: center;
}

.HomePage55b17fdf5368485cA4dfC81a5e4ccfed .PageQuote {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.HomePage55b17fdf5368485cA4dfC81a5e4ccfed .PageQuote > * {
  flex-grow: 1;
}

.HomePage55b17fdf5368485cA4dfC81a5e4ccfed .PageQuote .Image {
  object-fit: cover;
}

.HomePage55b17fdf5368485cA4dfC81a5e4ccfed .PageQuote .TextContent {
  background-color: #6950a8;
  color: white;
  padding: 3rem;
}

.HomePage55b17fdf5368485cA4dfC81a5e4ccfed .PageQuote .Title {
  font-family: "Bebas Neue";
  font-size: 350%;
  line-height: 100%;
}

.HomePage55b17fdf5368485cA4dfC81a5e4ccfed .PageQuote .Text {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 125%;
}

@media (min-width: 900px) {
  .HomePage55b17fdf5368485cA4dfC81a5e4ccfed .PageQuote > * {
    width: 50%;
    height: 26rem;
  }
}

@media (min-width: 1200px) {
  .HomePage55b17fdf5368485cA4dfC81a5e4ccfed .PageQuote .Title {
    font-size: 400%;
    line-height: 125%;
  }
}
.HomeHeader670e8f4bA00447bc936dF6b6edad028d {
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
  color: #030C22;
}

.HomeHeader670e8f4bA00447bc936dF6b6edad028d .background-image {
  opacity: 0.5;
}

.HomeHeader670e8f4bA00447bc936dF6b6edad028d .header-top-wrapper {
  position: relative;
}

.HomeHeader670e8f4bA00447bc936dF6b6edad028d .header-top-opacity {
  opacity: .8;
  background-color: white;
  position: absolute;
  inset: 0;
}

.HomeHeader670e8f4bA00447bc936dF6b6edad028d .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.MessagePages96b75e7cF4ce43f7A580A3d19425300b {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.MarketingBody5a5c272e306d4d2a9c4051a0ca5cf2de {
  padding: 2em;
  background-color: #f9f5e3;
}
.PageQuote8c0380c0860742ac97baB186e4b3daf7 {
  display: flex;
  flex-wrap: wrap;
}
.DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 7rem;
  padding: 1rem;
  position: relative;
}

.DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 .linked-logo {
  display: flex;
  justify-content: center;
}

.DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 .logo {
  width: 10rem;
}

.DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 .navigation {
  margin: 1rem;
  margin-top: 2.25rem;
}

.DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 .Navigation {
  display: none;
  flex-grow: 1;
  gap: 2rem;
  font-family: Montserrat;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: #500064;
}

.SideCar {
  background-color: #ccc;
  padding: 2rem;
}

.SideCar .Navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #500064;
  font-family: Montserrat;
  text-transform: uppercase;
}

.SideCar .Navigation .Link {
  display: block;
}
.SideCar .Navigation .Link:hover, .SideCar .Navigation .Link:active {
  filter: brightness(150%);
}

.DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 .NavigationMenuButton {
  color: black;
  opacity: 0.5;
  display: flex;
}

.DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

/* Show/hide navigation */

@media (min-width: 900px) {
  .DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 {
    padding: 2.25rem 1.75rem 1rem 1.75rem;
    height: 9rem;
  }

  .DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 .linked-logo {
    flex-grow: 1;
  }
  .DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 .logo {
    width: 12rem;
  }

  .DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 .navigation {
    flex-grow: 4;
  }

  .DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 .Navigation {
    display: flex;
  }

  .DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 .Navigation {
    font-size: 120%;
  }
  .DefaultHeaderTopAd4890a7Ac874acd939dAd759e9410b4 .logo {
    width: 15.25rem;
  }
}
.SecondaryPage1e69228aCa1d475fA948Cfa7b5989cc4 .text-content {
  padding: 0 1rem;
}
.AboutLevelingPageE967d8c724e64625Af4a0d2ea78d7a8a .Button {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  background: rgb(120, 0, 80);
  letter-spacing: 0.1rem;
}
.SecondaryMarketingHero61750d6a631f42a09030F72ac0218e5f {
  align-items: center;
  color: #030C22;
  display: flex;
  flex-grow: 1;
  padding-left: 5rem;
}

.SecondaryMarketingHero61750d6a631f42a09030F72ac0218e5f .title {
  color: #500064;
  font-family: 'Bebas Neue';
  font-size: 300%;
  font-weight: 500;
  line-height: 100%;
}

@media (min-width: 640px) {
  .SecondaryMarketingHero61750d6a631f42a09030F72ac0218e5f .title {
    font-size: 400%;
  }
}

.SecondaryMarketingHero61750d6a631f42a09030F72ac0218e5f .button > a {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 110%;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.5rem 2rem;
  background-color: #ed2e38;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.SecondaryMarketingHero61750d6a631f42a09030F72ac0218e5f .button > a:hover {
  --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.HomePageHero8b5ee7897d9746cfA7dcB226afdd042f {
  color: #030C22;
  min-height: 35rem;
  padding: 1.5rem;
  position: relative;
  text-align: left;
}

@media (min-width: 768px) {
  .HomePageHero8b5ee7897d9746cfA7dcB226afdd042f {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.HomePageHero8b5ee7897d9746cfA7dcB226afdd042f .title {
  color: black;
  font-family: 'Bebas Neue';
  font-size: 300%;
  font-weight: 500;
  line-height: 4rem;
  margin-bottom: 1.2rem;
  letter-spacing: 0.15rem;
  text-align: center;
}

.HomePageHero8b5ee7897d9746cfA7dcB226afdd042f .button {
  display: flex;
  justify-content: center;
}

.HomePageHero8b5ee7897d9746cfA7dcB226afdd042f .button > a {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  color: white;
  font-family: 'Bebas Neue';
  font-weight: 600;
  font-size: 150%;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.75rem 3rem;
  background-color: #780050;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.HomePageHero8b5ee7897d9746cfA7dcB226afdd042f .button > a:hover {
  --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (min-width: 900px) {
  .HomePageHero8b5ee7897d9746cfA7dcB226afdd042f .title {
    margin-top: 2.2rem;
    text-align: left;
  }

  .HomePageHero8b5ee7897d9746cfA7dcB226afdd042f .button {
    display: block;
    justify-content: start;
  }
}
.ShortHeader18486a18903c470f90fe5a359d804203 {
  background-color: white;
  color: #030C22;
  position: relative;
}

.ShortHeader18486a18903c470f90fe5a359d804203 .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg);
  background-size: cover;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.3;
}

.ShortHeader18486a18903c470f90fe5a359d804203 .header-top {
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  padding: 0 1.5rem;
  position: relative;
}

.ShortHeader18486a18903c470f90fe5a359d804203 .header-top .linked-logo {
  display: flex;
  justify-content: center;
}

.ShortHeader18486a18903c470f90fe5a359d804203 .header-top .logo {
  width: 8rem;
}

.ShortHeader18486a18903c470f90fe5a359d804203 .header-top .navigation {
  margin: 1rem;
  margin-top: 2.25rem;
}

.ShortHeader18486a18903c470f90fe5a359d804203 .header-top .Navigation {
  display: none;
  flex-grow: 1;
  gap: 2rem;
  font-family: Montserrat;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: #500064;
}

.ShortHeader18486a18903c470f90fe5a359d804203 .header-top .NavigationMenuButton {
  color: black;
  opacity: 0.5;
  display: flex;
}

.ShortHeader18486a18903c470f90fe5a359d804203 .header-top .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

.SideCar {
  background-color: #ccc;
  padding: 2rem;
}

.SideCar .Navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #500064;
  font-family: Montserrat;
  text-transform: uppercase;
}

.SideCar .Navigation .Link {
  display: block;
}
.SideCar .Navigation .Link:hover, .SideCar .Navigation .Link:active {
  filter: brightness(150%);
}

/* Larger screens */

@media (min-width: 900px) {
  .ShortHeader18486a18903c470f90fe5a359d804203 .header-top .linked-logo {
    flex-grow: 1;
  }
  .ShortHeader18486a18903c470f90fe5a359d804203 .header-top .logo {
    width: 9rem;
  }

  .ShortHeader18486a18903c470f90fe5a359d804203 .header-top .navigation {
    flex-grow: 4;
  }

  .ShortHeader18486a18903c470f90fe5a359d804203 .header-top .Navigation {
    display: flex;
  }

  .ShortHeader18486a18903c470f90fe5a359d804203 .header-top .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .ShortHeader18486a18903c470f90fe5a359d804203 .header-top {
    padding: 0 1.75rem;
    height: 5rem;
  }

  .ShortHeader18486a18903c470f90fe5a359d804203 .header-top .logo {
    width: 10rem;
  }
}
.DefaultFooter9bbf9896393647e38146057d38e5d5fb {
  background-color: #500064;
  color: white;
  min-height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 2rem;
  font-family: Montserrat;
}

.DefaultFooter9bbf9896393647e38146057d38e5d5fb > * {
  text-align: center;
}

.DefaultFooter9bbf9896393647e38146057d38e5d5fb .Navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  justify-content: center;
  margin-bottom: 2rem;
}

.DefaultFooter9bbf9896393647e38146057d38e5d5fb .Navigation .Link:hover {
  text-decoration-line: underline;
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.DefaultFooter9bbf9896393647e38146057d38e5d5fb > .logo-wrapper {
  width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DefaultFooter9bbf9896393647e38146057d38e5d5fb .LogoWrapper {
  font-size: 90%;
}

.DefaultFooter9bbf9896393647e38146057d38e5d5fb .LogoWrapper {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.DefaultFooter9bbf9896393647e38146057d38e5d5fb .LogoWrapper:hover {
    --tw-text-opacity: 1;
    color: rgb(0 194 152 / var(--tw-text-opacity));
}

.DefaultFooter9bbf9896393647e38146057d38e5d5fb .Logo {
  height: 40px;
  width: 150px;
  fill: currentColor;
}

.DefaultFooter9bbf9896393647e38146057d38e5d5fb .GroupCopyright {
  font-size: 90%;
}

.DefaultFooter9bbf9896393647e38146057d38e5d5fb .GroupFlowTag .PoweredBy {
  font-size: 90%;
}
.SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 {
  height: 18rem;
  position: relative;
  color: #030C22;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/spp/images/sky-background.jpg);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -10;
}

.SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 .header-top-wrapper {
  position: relative;
}

.SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 .header-top-opacity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: white;
  z-index: 0;
}

.SecondaryMarketingHeader13467bbeB70146369480940f3eef2555 .header-top {
  z-index: 1;
  position: relative;
}
.SecondaryNav6840fc939fc649a6A05303d9151ca68d {
  padding: 1rem 0;
}